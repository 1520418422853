import { DISABLE_DARK, ENABLE_DARK } from "../types/DarkTypes";

const DarkReducer = (state, action) => {
  if (action.type === ENABLE_DARK) {
    return {
      ...state,
      isDark: true,
    };
  }
  if (action.type === DISABLE_DARK) {
    return {
      ...state,
      isDark: false,
    };
  }
  return state;
};

export default DarkReducer;
