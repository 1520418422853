const gallery = [
  {
    id: 1,
    name: "12 Con Giáp",
    image: "/assets/gallery/12ConGiap.jpg",
    url: "https://www.youtube.com/embed/_YQSHpvmMts",
  },
  {
    id: 2,
    name: "Angle cinematic",
    image: "/assets/gallery/Angle.jpg",
    url: "https://www.youtube.com/embed/4x_Hl7T3krc",
  },
  {
    id: 3,
    name: "Bổ Phế Nam Hà",
    image: "/assets/gallery/BoPheNamHa.jpg",
    url: "https://www.youtube.com/embed/lgL0C-ZpRBs",
  },
  {
    id: 4,
    name: "Car crash - Maya animation",
    image: "/assets/gallery/CarCrashSimulation.jpg",
    url: "https://www.youtube.com/embed/pYyXhIgzuII",
  },
  {
    id: 5,
    name: "Environments - Unreal Engine 5",
    image: "/assets/gallery/DienBienUnrealEngine.jpg",
    url: "https://www.youtube.com/embed/7hrslx5Z36c",
  },
  {
    id: 6,
    name: "Character Modeling",
    image: "/assets/gallery/GameNFT.jpg",
    url: "",
  },
  {
    id: 7,
    name: "Game",
    image: "/assets/gallery/GameNFT2.jpg",
    url: "",
  },
  {
    id: 8,
    name: "Anime Animation",
    image: "/assets/gallery/GameWibu.png",
    url: "https://www.youtube.com/embed/_XGu7__Pk3o",
  },
  {
    id: 9,
    name: "Anime Animation",
    image: "/assets/gallery/GameWibu2.png",
    url: "https://www.youtube.com/embed/_XGu7__Pk3o",

  },
  {
    id: 10,
    name: "Phân bón Miền Nam",
    image: "/assets/gallery/PhanBonMienNam.jpg",
    url: "",
  },
  {
    id: 11,
    name: "3D animation air conditioner",
    image: "/assets/gallery/Robot.jpg",
    url: "https://www.youtube.com/embed/vF77cCDsUVc",
  },
  {
    id: 12,
    name: "Spaceship - Unreal Engine 5",
    image: "/assets/gallery/SpaceshipUnrealEngine.jpg",
    url: "https://www.youtube.com/embed/OMgTTogEiyI",
  },
  {
    id: 13,
    name: "Tiger cup 3D Cinematic",
    image: "/assets/gallery/Tiger.jpg",
    url: "https://www.youtube.com/embed/zfjy3tb2t1o",
  },
  {
    id: 14,
    name: "Titan battle short cinematic - Unreal Engine 5",
    image: "/assets/gallery/GameNFT3.jpg",
    url: "https://www.youtube.com/embed/tGIw9NW-xGo",
  },
  {
    id: 15,
    name: "Art 2D - Archer Pose",
    image: "/assets/gallery/2d_archerpose.jpg",
  },
  {
    id: 16,
    name: "Art 2D - PJ2",
    image: "/assets/gallery/2d_PJ2.jpg",
  },
  {
    id: 17,
    name: "Art 2D - PJ5",
    image: "/assets/gallery/2d_PJ5.jpg",
  },
  {
    id: 18,
    name: "Art 2D - Tiger",
    image: "/assets/gallery/2d_tiger.png",
  },
  {
    id: 19,
    name: "Art 2D - Trần Hưng Đạo Design",
    image: "/assets/gallery/2d_tranhungdaodesign.jpg",
  },
  {
    id: 20,
    name: "Art 2D - Wizard Pose",
    image: "/assets/gallery/2d_wizardpose.jpg",
  },
  {
    id: 21,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_1.png",
  },
  {
    id: 22,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_2.jpg",
  },
  {
    id: 23,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_3.jpg",
  },
  {
    id: 24,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_4.jpg",
  },
  {
    id: 25,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_5.jpg",
  },
  {
    id: 26,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_6.jpg",
  },
  {
    id: 27,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_7.jpg",
  },
  {
    id: 28,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_8.jpg",
  },
  {
    id: 29,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_9.jpg",
  },
  {
    id: 30,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_10.jpg",
  },
  {
    id: 31,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_11.jpg",
  },
  {
    id: 32,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_12.jpg",
  },
  {
    id: 33,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_13.jpg",
  },
  {
    id: 34,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_14.jpg",
  },
  {
    id: 35,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_15.jpg",
  },
  {
    id: 36,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_16.jpg",
  },
  {
    id: 37,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_17.jpg",
  },
  {
    id: 38,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_18.jpg",
  },
  {
    id: 39,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_19.jpg",
  },
  {
    id: 40,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_20.jpg",
  },
  {
    id: 41,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_21.jpg",
  },
  {
    id: 42,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_22.jpg",
  },
  {
    id: 43,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_23.jpg",
  },
  {
    id: 44,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_24.jpg",
  },
  {
    id: 45,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_25.jpg",
  },
  {
    id: 46,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_26.jpg",
  },
  {
    id: 47,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_27.jpg",
  },
  {
    id: 48,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_28.jpg",
  },
  {
    id: 49,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_29.jpg",
  },
  {
    id: 50,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_30.jpg",
  },
  {
    id: 51,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_31.jpg",
  },
  {
    id: 52,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_32.jpg",
  },
  {
    id: 53,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_33.jpg",
  },
  {
    id: 54,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_34.jpg",
  },
  {
    id: 55,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_35.jpg",
  },
  {
    id: 56,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_36.jpg",
  },
  {
    id: 57,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_37.jpg",
  },
  {
    id: 58,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_38.jpg",
  },
  {
    id: 59,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_39.jpg",
  },
  {
    id: 60,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_40.jpg",
  },
  {
    id: 61,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_41.jpg",
  },
  {
    id: 62,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_42.jpg",
  },
  {
    id: 63,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_43.jpg",
  },
  {
    id: 64,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_44.jpg",
  },
  {
    id: 65,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_45.jpg",
  },
  {
    id: 66,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_46.jpg",
  },
  {
    id: 67,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_47.jpg",
  },
  {
    id: 68,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_48.jpg",
  },
  {
    id: 69,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_49.jpg",
  },
  {
    id: 70,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_50.jpg",
  },
  {
    id: 71,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_51.jpg",
  },
  {
    id: 72,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_52.jpg",
  },
  {
    id: 73,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_53.jpg",
  },
  {
    id: 74,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_54.jpg",
  },
  {
    id: 75,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_55.jpg",
  },
  {
    id: 76,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_56.jpg",
  },
  {
    id: 77,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_57.jpg",
  },
  {
    id: 78,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_58.jpg",
  },
  {
    id: 79,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_59.jpg",
  },
  {
    id: 80,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_60.jpg",
  },
  {
    id: 81,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_61.jpg",
  },
  {
    id: 82,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_62.jpg",
  },
  {
    id: 83,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_63.jpg",
  },
  {
    id: 84,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_64.jpg",
  },
  {
    id: 85,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_65.jpg",
  },
  {
    id: 86,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_66.png",
  },
  {
    id: 87,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_67.png",
  },
  {
    id: 88,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_68.png",
  },
  {
    id: 89,
    name: "Art 2D",
    image: "/assets/gallery/Art2D/art2d_69.png",
  },
];

export default gallery;
