import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Header from "../components/Header";
import ContactForm from "../components/page-parts/ContactForm";
import PageContainer from "../components/page-parts/PageContainer";
import DarkContext from "../context/DarkContext";
import { DISABLE_DARK } from "../context/types/DarkTypes";

const Contact = (props) => {
  //! State
  const [state] = useState({
    heading: "Contact Page",
    pageHeading: "IKU Studios",
    address:
      "Address: Tầng 3, Toà B, Việt Đức Complex, Số 39 Lê Văn Lương, Thanh Xuân, Hà Nội",
    phone: "0379526592",
    fanpage: 'https://www.facebook.com/Ikustudios/',
    gmail: 'contact@ikustudios.com'
  });

  const { dark, dispatch: dispatchDark } = useContext(DarkContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchDark({ type: DISABLE_DARK });
  }, []);

  //! Function

  //! Render
  return (
    <>
      <Helmet>
        <title>IKU Studio | Contact</title>
        <meta name="description" content="contact page" />
      </Helmet>
      <Header isHomePage />
      <PageContainer data={state}>
        <ContactForm />
      </PageContainer>
    </>
  );
};

export default Contact;
