import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routeUrl from "./constants/routeUrl";
import PageNotFound from "./pages/404";
import About from "./pages/About";
import HomePage from "./pages/Home";
import Nav from "./components/Nav";
import Toggle from "./components/Toggle";
import ModelProvider from "./context/providers/ModelProvider";
import NavProvider from "./context/providers/NavProvider";
import { HelmetProvider } from "react-helmet-async";
import DestinationsProvider from "./context/providers/DestinationsProvider";
import Details from "./pages/Details";
import ServicesProvider from "./context/providers/ServicesProvider";
import GalleryProvider from "./context/providers/GalleryProvider";
import Contact from "./pages/Contact";
import AnimationsProvider from "./context/providers/AnimationContext";
import PartnerProvider from "./context/providers/PartnerProvider";
import Portfolio from "./pages/Portfolio";
import Directors from "./pages/Directors";
import LoadingProvider from "./context/providers/LoadingProvider";
import Loading from "./components/Loading";
import DarkProvider from "./context/providers/DarkProvider";

function App() {
  //! State
  const [isLoading, setIsLoading] = useState(true);

  const handleLoading = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    window.addEventListener("load", handleLoading);
    return () => window.removeEventListener("load", handleLoading);
  }, []);
  //! Function

  //! Render
  return (
    <LoadingProvider>
        <Router>
          <DarkProvider>
            <ModelProvider>
              <NavProvider>
                <PartnerProvider>
                  <GalleryProvider>
                    <DestinationsProvider>
                      <ServicesProvider>
                        <AnimationsProvider>
                          <Toggle />
                          <Nav />
                          <HelmetProvider>
                            <Switch>
                              <Route path={routeUrl.HOME_PAGE} exact>
                                <HomePage />
                              </Route>
                              <Route path={routeUrl.PORTFOLIO_PAGE} exact>
                                <Portfolio />
                              </Route>
                              <Route path={routeUrl.ABOUT_PAGE}>
                                <Directors />
                              </Route>
                              <Route path={routeUrl.CONTACT_PAGE}>
                                <Contact />
                              </Route>
                              <Route path={routeUrl.DETAIL_PAGE} exact>
                                <Details />
                              </Route>
                              {/*  */}
                              <Route>
                                <PageNotFound />
                              </Route>
                            </Switch>
                          </HelmetProvider>
                        </AnimationsProvider>
                      </ServicesProvider>
                    </DestinationsProvider>
                  </GalleryProvider>
                </PartnerProvider>
              </NavProvider>
            </ModelProvider>
          </DarkProvider>
        </Router>
    </LoadingProvider>
  );
}

export default App;
