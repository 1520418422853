import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Login from "../auth/Login";
import Register from "../auth/Register";
import BrandProject from "../components/brand-project/BrandProject";
import Destinations from "../components/Destinations";
import Footer from "../components/footer/Footer";
import Header from "../components/Header";
import Intro from "../components/Intro";
import Modal from "../components/Modal";
import Partner from "../components/partner/Partner";
import PreviewVideo from "../components/PreviewVideo";
import DarkContext from "../context/DarkContext";
import GalleryContext from "../context/GalleryContext";
import ModelContext from "../context/ModelContext";
import { DISABLE_DARK } from "../context/types/DarkTypes";
import { OPEN_LIGHTBOX } from "../context/types/GalleryTypes";

const HomePage = (props) => {
  //! State
  const [state] = useState({
    heading: "",
    paragraph: "",
    icon: "/assets/icons/play-video.svg",
  });
  
  const [dataVideoHeader] = useState({
      name: "",
      image: "/assets/images/screen.jpg",
      url: "https://www.youtube.com/embed/tr8DodoUBjo",
  })

  const [registerModel] = useState("registerModel");

  const [loginModel] = useState("loginModel");

  const { dispatch } = useContext(ModelContext);

  const {
    galleryStore: { gallery, lightBoxStatus },
    dispatch: dispatchView
  } = useContext(GalleryContext);

  const { dark, dispatch: dispatchDark } = useContext(DarkContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchDark({ type: DISABLE_DARK });
  },[]);

  //! Function
  const openLightBox = (value) => {
    dispatchView({ type: OPEN_LIGHTBOX, payload: value });
  };

  //! Render
  return (
    <>
    {lightBoxStatus ? <PreviewVideo /> : ""}
      <Helmet>
        <title>IKU Studios</title>
        <meta
          name="description"
          content="IKU Studios"
        />
        <meta name="keywords" content="iku, ikustudios studios, ikustu" />
      </Helmet>
      {/*  */}
      <Header heading={state.heading} paragraph={state.paragraph} image={state.image} isHomePage>
        <div className="header__contents__text__child__link">
          <button
            className="btn-view"
            onClick={() => openLightBox(dataVideoHeader)}
          >
            <img  src={state.icon}/>
            <p>View Rell</p>
          </button>
        </div>
      </Header>
      {/*  */}
      {/* <Intro /> */}
      <Destinations isAnimation={false} />
      {/* <BrandProject /> */}
      {/* <Partner /> */}
      {/*  */}
      <Footer />
      {/* MODAL */}
      <Modal current={registerModel}>
        <Register currentModel={loginModel} />
      </Modal>
      <Modal current={loginModel}>
        <Login currentModel={registerModel} />
      </Modal>
    </>
  );
};

export default HomePage;
