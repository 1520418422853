import React, { useContext, useState } from "react";
import ServicesContext from "../../context/ServicesContext";
import ServicesLeft from "./ServicesLeft";
import ServicesList from "./ServicesList";

const Services = (props) => {
  //! State
  const {
    servicesData: { servicesData },
  } = useContext(ServicesContext);

  const [state] = useState({
    subHeading1: "Meet your new team.",
    subHeading:
      "IKU is a creative production company specializing in animation and motion graphics driven by compelling storytelling and distinct design. We work with agencies, businesses and brands from all over the world, covering a wide range of industries spanning from culture and entertainment to real estate and technology such as Panamotion, Wondershare Technology, Garena, Riot Games, Vingroup, Kandy Soundwave, Yeah1 and more.",
    subHeading2:
      "Since IKU studio was established, we have been building a talent - leading team who are creatively ambitious and eager to collaborate with and listen to our clients. We adapt our dynamic structure to every project we touch, always collaborating with the best talent in the industry. Think of us as an extension of your team.",
    subHeading3:
      "Our clients list includes Panamotion, Wondershare Technology, Garena, Riot Games, Vingroup, Kandy Soundwave, Yeah1 and more.",
    subHeading4:
      "High-end designs and successful visual storytelling are the propelling forces we use to create content that makes the change. But they also change us, make us better, keep us open, creative and thirsty for more.",
  });
  //! Function

  //! Render
  return (
    <div className="services">
      <div className="services__contents">
        <div className="container">
          <div className="row ml-minus-15 mr-minus-15 services__contents__container">
            <div className="col-4 p-15">
              <ServicesLeft heading={state.subHeading1} />
            </div>
            <div className="col-4 p-15">
              <ServicesLeft subHeading={state.subHeading} subHeading2={state.subHeading2} />
            </div>
            <div className="col-4 p-15">
              <ServicesLeft subHeading={state.subHeading3} subHeading2={state.subHeading4} />
            </div>
            <div className="row col-12 p-15">
              <ServicesList services={servicesData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
