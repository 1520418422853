import React, { useReducer } from "react";
import LoadingReducer from "../../context/reducers/LoadingReducer";
import LoadingContext from "../../context/LoadingContext";
const LoadingProvider = (props) => {
  //! State
  const [loading, dispatch] = useReducer(LoadingReducer, {
    loading: false,
  });

  //! Function

  //! Render
  return (
    <LoadingContext.Provider value={{ loading, dispatch }}>
      {props.children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
