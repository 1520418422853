import React, { useContext, useEffect, useRef, useState } from "react";
import GalleryContext from "../context/GalleryContext";
import DestinationsList from "./DestinationsList";
import Masonry from "react-responsive-masonry";
import PreviewVideo from "./PreviewVideo";

const propTypes = {};

const Destinations = ({ isAnimation = true }) => {
  //! State
  const {
    galleryStore: { gallery, lightBoxStatus },
  } = useContext(GalleryContext);

  const [state] = useState({
    heading:
      "Discover the most engaging places in the world with Travel Friends",
    paragraph:
      "Travel is a brutality. It forces you to trust strangers and to lose sight of all that familiar comforts of home and friends.You are constantly of balance. Nothing is yours except the essential things Air, Sleep, Dream, the sea, the sky-all things tending towards the eternal or what we imagine of it.",
  });
  //! Function

  //! Render
  return (
    <>
      {lightBoxStatus ? <PreviewVideo /> : ""}
      <div className="destinations" id="destinations">
        <div className="container">
          <div className="destinations__block">
            <div className="destinations__block__grid mr-minus-15 ml-minus-15">
              <DestinationsList
                isAnimation={isAnimation}
                destination={gallery[4]}
              />
              <Masonry columnsCount={2} gutter={2}>
                {gallery.map((gallery) => (
                  <DestinationsList destination={gallery} />
                ))}
              </Masonry>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Destinations.propTypes = propTypes;
export default Destinations;
