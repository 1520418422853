import React, { useReducer } from "react";
import DarkContext from "../DarkContext";
import DarkReducer from "../reducers/DarkReducer";
const DarkProvider = (props) => {
  //! State
  const [dark, dispatch] = useReducer(DarkReducer, {
    isDark: false,
  });

  //! Function

  //! Render
  return (
    <DarkContext.Provider value={{ dark, dispatch }}>
      {props.children}
    </DarkContext.Provider>
  );
};

export default DarkProvider;
