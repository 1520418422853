import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet-async";
import Footer from "../components/footer/Footer";
import Services from "../components/services/Services";
import Header from "../components/Header";

const propTypes = {

}

const Directors = (props) => {
  //! State
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //! Function

  //! Render
  return (
    <>
    <Helmet>
      <title>IKU Studios | About</title>
      <meta
        name="description"
        content="IKU Studios"
      />
      <meta name="keywords" content="iku, ikustudios studios, ikustu" />
    </Helmet>
    <Header />
    {/*  */}
      <Services />
    {/*  */}
    <Footer />
  </>
  )
}

Directors.propTypes = propTypes;
export default Directors;