import React from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../footer/Footer";

const PageContainer = ({ data, children }) => {
  //! State

  //! Function

  //! Render
  return (
    <>
      <Header heading={data.heading} />
      <div className="page">
        <div className="container">
          <div className="row">
            <div className="col-6">{children}</div>
            <div className="col-6">
              <div className="page__info">
                <h1 className="page__info__heading animation">{data.pageHeading}</h1>
                <p className="page__info__msg animation">FanPage: <a href={data.fanpage} target="_blank">IKU Studios</a></p>
                <p className="page__info__msg animation"><a href={`mailto:${data.gmail}`}>Gmail: {data.gmail}</a></p>
                <p className="page__info__msg animation">{data.address}</p>
                <p className="page__info__msg animation">Hotline: <a href={`tel:${data.phone}`}>{data.phone}</a></p>
                <div className="page__info__link animation">
                  <Link to="/" className="btn-dark">
                    Go Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PageContainer;
