import React from "react";

const ServicesLeft = ({ heading, subHeading, subHeading2 }) => {
  //! State

  //! Function

  //! Render
  return (
    <div className="services__left">
      <h1 className="services__left__heading ">{heading}</h1>
      <p className="services__left__paragraph">{subHeading}</p>
      <br></br>
      <p className="services__left__paragraph animation">{subHeading2}</p>
    </div>
  );
};

export default ServicesLeft;
