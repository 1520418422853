import React, { useContext } from "react";
import { Link } from "react-router-dom";
import routeUrl from "../constants/routeUrl";
import DarkContext from "../context/DarkContext";
import NavContext from "../context/NavContext";

const Nav = (props) => {
  //! State
  const { state, dispatch } = useContext(NavContext);
  const { dark} = useContext(DarkContext);

  const isDarkTheme = dark && dark.isDark;
  //! Function

  //! Render
  return (
    <>
      {state ? <div className="navLayer"></div> : ""}
      <div className={state ? "nav nav--open" : "nav nav--close"}>
        <div className={`nav__content`}>
          <li>
            <a href={routeUrl.HOME_PAGE}>Home</a>
          </li>
          <li>
            <a href={routeUrl.PORTFOLIO_PAGE}>Portfolio</a>
          </li>
          <li>
            <a href={routeUrl.ABOUT_PAGE}>About</a>
          </li>
          <li>
            <a href={routeUrl.CONTACT_PAGE}>Contact</a>
          </li>
        </div>
      </div>
     
    </>
  );
};

export default Nav;
