const partner = [
  {
    id: 1,
    name: "12 Con Giáp",
    image: "/assets/partner/1.png",
    url: "",
  },
  {
    id: 2,
    name: "Angle",
    image: "/assets/partner/2.png",
    url: "",
  },
  {
    id: 3,
    name: "Bổ Phế Nam Hà",
    image: "/assets/partner/3.png",
    url: "",
  },
  {
    id: 4,
    name: "Car Crash Simulation",
    image: "/assets/partner/4.png",
    url: "",
  },
  {
    id: 5,
    name: "Điện Biên Unreal Engine 5",
    image: "/assets/partner/5.png",
    url: "",
  },
  {
    id: 6,
    name: "Game",
    image: "/assets/partner/6.png",
    url: "",
  },
  {
    id: 7,
    name: "Game",
    image: "/assets/partner/7.png",
    url: "",
  },
  {
    id: 8,
    name: "Game",
    image: "/assets/partner/8.png",
    url: "",
  },
];

export default partner;
