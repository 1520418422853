import React, { useContext, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useHistory } from "react-router-dom";
import routeUrl from "../constants/routeUrl";
import LoadingContext from "../context/LoadingContext";
import { DISABLE_LOADING, ENABLE_LOADING } from "../context/types/LoadingTypes";

const Header = ({
  heading,
  paragraph,
  children,
  image,
  isHeading = false,
  isHomePage = false,
}) => {
  //! State
  const [state] = useState({
    video: "/assets/videos/header.mp4",
    poster: "/assets/images/screen.png",
    logo: "/assets/images/logo.png",
    gif: "/assets/videos/loading.gif"
  });

  const { push } = useHistory();

  const { dispatch: dispatchLoading } = useContext(LoadingContext);

  //! Function
  const goToHome = (e) => {
    push(routeUrl.HOME_PAGE);
  };
  const setVideoLoading = () => {
    dispatchLoading({ type: DISABLE_LOADING });
  };
  const setVideoLoaded = () => {
    dispatchLoading({ type: ENABLE_LOADING });
  };
  //! Render
  return (
    <div className={`header ${isHomePage ? "" : "homepage"}`}>
      <div className="container-header pr">
        <div className="header__logo">
          <LazyLoadImage src={state.logo} alt="logo" onClick={goToHome} />
        </div>
      </div>
      {isHomePage ? (
        <>
          <div className="header__video">
            {image ? (
              <LazyLoadImage src={image} alt={image} />
            ) : (
              <>
                <LazyLoadImage className="header__video__img" src={state.gif} alt={state.gif} />
                <video
                  src={state.video}
                  autoPlay="autoplay"
                  loop
                  muted
                  preload="auto"
                  type="video/mp4"
                  poster={state.poster}
                  onLoadStart={() => {
                    setVideoLoading();
                  }}
                  onLoadedData={() => {
                    setVideoLoaded();
                  }}
                />
              </>
            )}
          </div>

          <div className="header__contents">
            <div className="containter">
              <div
                className={`header__contents__text ${
                  isHeading ? `isheading` : ""
                }`}
              >
                <div className="header__contents__text__child">
                  {isHeading ? (
                    <div className="header__contents__text__child__heading">
                      <h1 className="header__contents__text__child__h1">
                        {heading}
                      </h1>
                      <p className="header__contents__text__child__p">
                        {paragraph}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {children}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Header;
