import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Destinations from "../components/Destinations";
import Footer from "../components/footer/Footer";
import Header from "../components/Header";
import DarkContext from "../context/DarkContext";
import { ENABLE_DARK } from "../context/types/DarkTypes";

const Portfolio = (props) => {
  //! State
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { dark, dispatch } = useContext(DarkContext);
  //! Function
  useEffect(() => {
    dispatch({ type: ENABLE_DARK });
  },[]);
  //! Render
  return (
    <>
      <Helmet>
        <title>IKU Studios | Portfolio</title>
        <meta name="description" content="IKU Studios" />
        <meta name="keywords" content="iku, ikustudios studios, ikustu" />
      </Helmet>
      {/*  */}
      <Header />
      <Destinations isAnimation={false} />
      {/*  */}
      <Footer />
    </>
  );
};

export default Portfolio;
