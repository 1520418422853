import React, { useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import GalleryContext from "../context/GalleryContext";
import { CLOSE_LIGHTBOX } from "../context/types/GalleryTypes";

const PreviewVideo = (props) => {
  //! State
  const {
    galleryStore: { currentLightBox },
    dispatch,
  } = useContext(GalleryContext);
  //! Function
  const closeLightBox = (e) => {
    const className = e.target.getAttribute("class");
    if (className === "preview-video") {
      dispatch({ type: CLOSE_LIGHTBOX });
    }
  };
  //! Render
  return (
    <div className="preview-video" onClick={closeLightBox}>
      <h4>{currentLightBox.name}</h4>
      <div className="preview-video__card">
        <div className="preview-video__card">
          {currentLightBox.url ? (
            <iframe
            width="960"
            height="540"
            autoplay="true"
            src={`${currentLightBox.url}?autoplay=1`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            ></iframe>
            ) : 
            <img className="preview-video__card__image" src={currentLightBox.image}/>}
        </div>
      </div>
    </div>
  );
};

export default PreviewVideo;
