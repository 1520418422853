import React, { useState } from "react";
import { BiChevronRight } from "react-icons/bi";
const ServicesList = ({ services }) => {
  //! State
  const [current, setCurrent] = useState(-1);
  const [toggle, setToggle] = useState(false);
  //! Function
  const showDetails = (index) => {
    setToggle(!toggle);
    return setCurrent(index);
  };
  //! Render
  return services.length > 0
    ? services.map((service, index) => (
        <div className="col-3 p-15 services__list" key={index}>
          <div
            className={`services__list__heading ${
              current === index
                ? "services__list__heading--active"
                : "services__list__heading--normal"
            }`}
            onClick={() => showDetails(index)}
          >
            <div className="services__list__heading__title">
              <div className="services__list__heading__title__image">
                <img src={service.image} />
              </div>
              <div className="services__list__heading__title__name">
                <div className="services__list__heading__title__name__field">
                  <p>{service.heading}</p>
                  <span>{service.rank}</span>
                </div>
              </div>
            </div>
          </div>
          <p
            className={`services__list__details ${
              current === index ? "services__list__details--show" : ""
            }`}
          >
            {service.p}
          </p>
        </div>
      ))
    : "";
};

export default ServicesList;
