export const services = [
  {
    id: 1,
    heading: "Chung",
    rank: 'Director',
    p: "In each country we have travel guides, they will help you throughout the tour and they are very friendly you can ask them any question and they will never mind.",
    image: "/assets/member/Chung.jpg",

  },
  {
    id: 2,
    heading: "Đạt",
    rank: 'Art 2D',
    p: "We have contracted with 5-star hotels in the available destinations, in those hotels, you will have all facilities ",
    image: "/assets/member/Dat.jpg",
  },
  {
    id: 3,
    heading: "Đức",
    rank: 'Video Editor',
    p: "Some of our packages have free diner for that you can check our packages",
    image: "/assets/member/Duc.jpg",
  },
  {
    id: 4,
    heading: "Hà",
    rank: 'Model',
    p: "We have good facilities for couples, in 5-star hotels, we room for couples, that's why peoples love our tours. ",
    image: "/assets/member/Ha.jpg",
  },
  {
    id: 5,
    heading: "Hiệp",
    rank: 'Model',
    p: "Some of our packages have free diner for that you can check our packages",
    image: "/assets/member/Hiep.jpg",
  },
  {
    id: 6,
    heading: "Hoàng",
    rank: 'Lead VFX',
    p: "Some of our packages have free diner for that you can check our packages",
    image: "/assets/member/Hoang.jpg",
  },
  {
    id: 7,
    heading: "Linh",
    rank: 'Video Editor',
    p: "Some of our packages have free diner for that you can check our packages",
    image: "/assets/member/Linh.jpg",
  },
  {
    id: 8,
    heading: "Mai Phương",
    rank: 'Saler',
    p: "Some of our packages have free diner for that you can check our packages",
    image: "/assets/member/MaiPhuong.jpg",
  },
  {
    id: 9,
    heading: "Nam",
    rank: 'CEO',
    p: "Some of our packages have free diner for that you can check our packages",
    image: "/assets/member/Nam.jpg",
  },
  {
    id: 10,
    heading: "Nam",
    rank: 'Animator',
    p: "Some of our packages have free diner for that you can check our packages",
    image: "/assets/member/Nam99.jpg",
  },
  {
    id: 11,
    heading: "Phương",
    rank: 'Art 2D',
    p: "Some of our packages have free diner for that you can check our packages",
    image: "/assets/member/Phuong99.jpg",
  },
  {
    id: 12,
    heading: "Thành",
    rank: 'Saler',
    p: "Some of our packages have free diner for that you can check our packages",
    image: "/assets/member/Thanh.jpg",
  },
  {
    id: 13,
    heading: "Trung",
    rank: 'Rigger',
    p: "Some of our packages have free diner for that you can check our packages",
    image: "/assets/member/Trung.jpg",
  },
  {
    id: 12,
    heading: "Vũ",
    rank: 'Animator',
    p: "Some of our packages have free diner for that you can check our packages",
    image: "/assets/member/Vu.jpg",
  },
];
