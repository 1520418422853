import React, { useContext, useEffect, useState } from "react";
import NavContext from "../context/NavContext";
import { NAV_TOGGLE } from "../context/types/NavTypes";
import routeUrl from "../constants/routeUrl";
import DarkContext from "../context/DarkContext";
const propTypes = {};

const Toggle = (props) => {
  //! State
  const { state, dispatch } = useContext(NavContext);

  const { dark } = useContext(DarkContext);

  const isDarkTheme = dark && dark.isDark;

  //!Function
  const [fixHeader, setFixHeader] = useState(true);

  //! Function
  function handleScroll() {
    const newPos = document.body.getBoundingClientRect().top;
    if (newPos < -200 && fixHeader)
      document.getElementById("navbar").style.opacity = "0";
    if (newPos > -150 && fixHeader)
      document.getElementById("navbar").style.opacity = "1";
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  //! Render
  return (
    <>
      <div className={state ? "toggle-close" : "toggle-open"}>
        <div
          className={`nav__header ${state ? "close" : "open"} ${
            isDarkTheme ? "dark-text" : ""
          }`}
          id="navbar"
        >
          <li>
            <a href={routeUrl.HOME_PAGE}>Home</a>
          </li>
          <li>
            <a href={routeUrl.PORTFOLIO_PAGE}>Portfolio</a>
          </li>
          <li>
            <a href={routeUrl.ABOUT_PAGE}>About</a>
          </li>
          <li>
            <a href={routeUrl.CONTACT_PAGE}>Contact</a>
          </li>
        </div>
        <div
          className={`toggle__span`}
          onClick={() => dispatch({ type: NAV_TOGGLE })}
        >
          <div className={isDarkTheme ? "dark-theme" : ""}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </>
  );
};

Toggle.propTypes = propTypes;
export default Toggle;
