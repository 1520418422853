import { DISABLE_LOADING, ENABLE_LOADING } from "../../context/types/LoadingTypes";

const LoadingReducer = (state, action) => {
  if (action.type === ENABLE_LOADING) {
    return {
      ...state,
      loading: true,
    };
  }
  if (action.type === DISABLE_LOADING) {
    return {
      ...state,
      loading: false,
    };
  }
  return state;
};

export default LoadingReducer;
