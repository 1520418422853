import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import GalleryContext from "../context/GalleryContext";
import { OPEN_LIGHTBOX } from "../context/types/GalleryTypes";

const DestinationsList = ({ destination,isAnimation = true }) => {
  //! State
  const [state] = useState({
    icon: "/assets/icons/play-video.svg",
    arrowRight: "/assets/icons/arrow-light.svg"
  });

  const { dispatch } = useContext(GalleryContext);
  //! Function
  const openLightBox = (imgObj) => {
    dispatch({ type: OPEN_LIGHTBOX, payload: imgObj });
  };

  //! Render
  return (
    <div className="col-12 p-15-reponsive">
      <div className={`destinations__card ${isAnimation ? `animation` : ""}`}>
        <div className="destinations__card__img">
          <img src={destination.image} alt={destination.image} />
        </div>
        <div className="destinations__card__info">
          <div className="destinations__card__info__text">
            <div className="destinations__card__info__text__title">
              {destination.name}
            </div>
            <div className="destinations__card__info__text__button">
              <div className="view-project">
                <Link className="btn-view" to={`/details/${destination.id}`}>
                  <p>View Project</p> <img src={state.arrowRight} />
                </Link>
              </div>
              <div
                className="btn-view"
                onClick={() => openLightBox(destination)}
              >
                <p>Quick Play</p> <img src={state.icon} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DestinationsList;
