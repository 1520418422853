import React, { useReducer } from "react";
import GalleryReducer from "../reducers/GalleryReducer";
import partner from "../../data/partner";
import PartnerContext from "../PartnerContext";
const PartnerProvider = (props) => {
  //! State
  const [partnerStore, dispatch] = useReducer(GalleryReducer, {
    partner,
  });

  //! Function

  //! Render
  return (
    <PartnerContext.Provider value={{ partnerStore, dispatch }}>
      {props.children}
    </PartnerContext.Provider>
  );
};

export default PartnerProvider;
